@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply rounded-md;
    @apply text-base;
    @apply text-white;
    @apply font-normal;

    background: linear-gradient(315deg, #6D54DE 0%, #9C58EF 100%);
    text-align: center;
    font-family: Heebo;
    @apply p-3;

    min-width: 220px;

    &--block {
      @apply block;
      width: 100%;
    }
    &--lg {
      @apply p-6;
      @apply text-xl;
    }
  }


}