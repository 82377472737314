@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-input {

    @apply rounded-md;
    @apply border;
    @apply border-solid;
    @apply text-base;
    @apply border-gray-200;
    @apply px-4;
    @apply py-3;
    @apply block;
    
    width: 100%;
  }

  .form-select {
    @apply rounded-md;
    @apply border;
    @apply border-solid;
    @apply text-base;
    @apply border-gray-200;
    @apply px-4;
    @apply py-3;
    @apply block;
    width: 100%;
  }
}