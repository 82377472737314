@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-primary {
    background: var(--degradado2, linear-gradient(315deg, #6D54DE 0%, #9C58EF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-primary {
    background-color: #7C2CFF;
  }

  .bg-secondary {
    background-color: #D7BEFF;
  }

  .section-error {
    @apply text-red-500;
    @apply flex;
    @apply items-center;
    @apply text-xs;
    @apply my-1
  }
}